<template>
  <div class="row bg-white">
    <div class="col-12">
      <div class="row">
        <c-card :noHeader="true" class="cardClassDetailForm dashborad">
          <template slot="card-detail">
            <div class="col-6">
              <apexchart 
                ref="seasonOcuurChart" 
                height="300px" 
                type="bar"
                :width="seasonOcuurChart.chartWidth"
                :options="seasonOcuurChart.chartOptions" 
                :series="seasonOcuurChart.series"></apexchart>
            </div>
            <div class="col-3">
              <apexchart 
                ref="kindOcuurChart1" 
                height="300px" 
                type="polarArea"
                :width="kindOcuurChart1.chartWidth"
                :options="kindOcuurChart1.chartOptions" 
                :series="kindOcuurChart1.series"></apexchart>
            </div>
            <div class="col-3">
              <apexchart 
                ref="kindOcuurChart2" 
                height="300px" 
                type="polarArea"
                :width="kindOcuurChart2.chartWidth"
                :options="kindOcuurChart2.chartOptions" 
                :series="kindOcuurChart2.series"></apexchart>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import ICountUp from "vue-countup-v2";
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'accident-status',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      searchParam: {
        plantCd: '',
        processCd: '',
      },
      items: [
        { title: 'firstLine', component: () => import(`${'./firstLineStatus.vue'}`) },
        { title: 'secondLine', component: () => import(`${'./secondLineStatus.vue'}`) },
      ],
      options: [],
    
      seasonOcuurChart: {
        chartOptions: {
          title: {
            text: '계절별 발생 건수'
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          chart: {
            type: 'bar',
            height: 400,
            stacked: true,
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          xaxis: {
            categories: ['1공장','2공장','3공장','4공장'],
            labels: {
              formatter: function (val) {
                return val + "건"
              }
            },
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return   val + " 건"
              }
            }
          }
        },
        series: [
          {
            name: '봄',
            data: [2, 6, 6, 6]
          },
          {
            name: '여름',
            data: [5, 5, 8, 1]
          },
          {
            name: '가을',
            data: [1, 1, 1, 1]
          },
          {
            name: '겨울',
            data: [2, 4, 6, 1]
          },
        ],
        chartWidth: '80%',
      },
      kindOcuurChart1: {
        chartOptions: {
          title: {
            text: '상해 종류별 발생 건수'
          },
          chart: {
            type: 'polarArea',
          },
          stroke: {
            colors: ['#fff']
          },
          labels: ['골절','동상','부종','찔림','타박','질식','창상','화상'],
          // '폭발','파열','화재','무리한 동작','이상온도 접촉','유해물 접촉'],
          responsive: [{
            breakpoint: 1000,
            options: {
              chart: {
                width: 500
              },
              
            }
          }],
          yaxis: {
            title: {
              text: undefined
            },
          },
          fill: {
            opacity: 0.8
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return   val + " 건"
              }
            }
          }
        },
        series: [14, 23, 21, 17, 15, 10, 12, 17,],
        //  21, 12, 12, 15, 21, 12],
        
        chartWidth: '100%',
      },
      kindOcuurChart2: {
        chartOptions: {
          title: {
            text: '재해 발생 형태별 발생 건수'
          },
          chart: {
            type: 'polarArea',
          },
          stroke: {
            colors: ['#fff']
          },
          labels: ['추락','전도','충돌','낙하','비례','붕괴','협착','감전'],
          // '폭발','파열','화재','무리한 동작','이상온도 접촉','유해물 접촉'],
          responsive: [{
            breakpoint: 1000,
            options: {
              chart: {
                width: 500
              },
            
            }
          }],
          yaxis: {
            title: {
              text: undefined
            },
          },
          fill: {
            opacity: 0.8
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return   val + " 건"
              }
            }
          }
        },
        series: [14, 23, 21, 17, 15, 10, 12, 17,],
        //  21, 12, 12, 15, 21, 12],
        
        chartWidth: '100%',
      },
      selectProcessCd: '',
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    // window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // this.clickFullScreen();
      // role setting
      // url setting
      // code setting
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important


.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-yearOcuurChart
  padding: 5px !important
.widget-yearOcuurChart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>